import { useCallback, useEffect, useMemo, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import {
  TManageWalletParams,
  TStaffListItem,
  TStoresListItem,
  useConcludeWalletsManagementMutation,
  useGetStaffListInfinityQuery,
  useGetStoresListInfinityQuery,
  useGetWalletsManagementDetailsQuery,
  useFlagAsExternalMutation,
  useManageWalletMutation,
  WalletManagementStatus
} from 'integration/resources/walletsManagement'
import { useBreakpointValue, useDisclose, useToast } from 'native-base'
import { Toast } from 'organisms'
import useDebounce from 'src/hooks/useDebounce'
import { useAuthAtomValue } from 'src/store/auth'

import { OrderField, Tab, UseStaffListScreen } from './StaffListScreen.types'

export const orderFields: OrderField[] = [
  {
    label: 'Nome',
    options: [
      { id: 'asc', name: 'A-Z' },
      { id: 'desc', name: 'Z-A' }
    ]
  },
  {
    label: 'Número de TABs',
    options: [
      { id: 'asc', name: 'Menor para o Maior' },
      { id: 'desc', name: 'Maior para o menor' }
    ]
  }
]

const manageFilters = [
  { id: 0, name: 'Todas' },
  { id: 1, name: 'Carteirizadas' },
  { id: 2, name: 'Não Carteirizadas' }
]

const mergeStaffInfiniteQuery = (data: ReturnType<typeof useGetStaffListInfinityQuery>['data']) =>
  data?.pages.reduce<TStaffListItem[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

const mergeStoresInfiniteQuery = (data: ReturnType<typeof useGetStoresListInfinityQuery>['data']) =>
  data?.pages.reduce<TStoresListItem[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useStaffListScreen: UseStaffListScreen = ({ navigation, route }) => {
  const leaderStdCode = route?.params?.userStdCode

  const authAtomValue = useAuthAtomValue()

  const isAdmin = useMemo(
    () => !!(authAtomValue?.user?.is_admin && leaderStdCode),
    [authAtomValue?.user?.is_admin, leaderStdCode]
  )

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  const [currentTab, setCurrentTab] = useState<Tab>(Tab.Specialists)

  const [timeouts, setTimeouts] = useState<number[]>([])

  const [searchStaff, onSearchStaff] = useState<string>('')

  const debouncedSearchStaff = useDebounce(searchStaff, 500)

  const [searchStore, onSearchStore] = useState<string>('')

  const debouncedSearchStore = useDebounce(searchStore, 500)

  const [searchManageStaff, onSearchManageStaff] = useState<string>('')

  const debouncedSearchManageStaff = useDebounce(searchManageStaff, 500)

  const [searchManageStore, onSearchManageStore] = useState<string>('')

  const debouncedSearchManageStore = useDebounce(searchManageStore, 500)

  const handleSearchClear = () => {
    onSearchStaff('')

    onSearchStore('')

    onSearchManageStaff('')

    onSearchManageStore('')
  }

  const [selectedOrderBy, setSelectedOrderBy] = useState<{
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }>({
    field: undefined,
    direction: undefined
  })

  const [selectedManageFilter, setSelectedManageFilter] = useState(manageFilters[0])

  const userStdCode = useMemo(
    () => authAtomValue?.user?.std_code ?? 0,
    [authAtomValue?.user?.std_code]
  )

  const [appliedOrderBy, setAppliedOrderBy] = useState<'name' | 'qtde_stores' | undefined>()

  const [appliedOrderByDirection, setAppliedOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >()

  const {
    data: staffListData,
    isLoading: staffListIsLoading,
    isFetchingNextPage: staffListIsFetchingNextPage,
    hasNextPage: staffListHasNextPage,
    fetchNextPage: staffListFetchNextPage
  } = useGetStaffListInfinityQuery({
    leader_std_code: Number(leaderStdCode) ?? userStdCode,
    order_by: appliedOrderBy,
    order_by_direction: appliedOrderByDirection,
    ...(debouncedSearchStaff?.length && { search: debouncedSearchStaff }),
    ...(debouncedSearchManageStaff?.length && { search: debouncedSearchManageStaff })
  })

  const staffData = useMemo(() => mergeStaffInfiniteQuery(staffListData), [staffListData])

  const staffTotalItems = staffListData?.pages[0].data.metadata.pagination.total ?? 0

  const onEndReachedStaff = useCallback(() => {
    if (!staffListIsFetchingNextPage && staffListHasNextPage) {
      staffListFetchNextPage()
    }
  }, [staffListIsFetchingNextPage, staffListHasNextPage, staffListFetchNextPage])

  const {
    isOpen: manageWalletModalIsOpen,
    onClose: manageWalletModalOnClose,
    onOpen: manageWalletModalOnOpen
  } = useDisclose()

  useEffect(() => {
    handleSearchClear()
  }, [manageWalletModalIsOpen])

  const {
    data: storesListData,
    isLoading: storesListIsLoading,
    isFetchingNextPage: storesListIsFetchingNextPage,
    hasNextPage: storesListHasNextPage,
    fetchNextPage: storesListFetchNextPage
  } = useGetStoresListInfinityQuery({
    leader_std_code: Number(leaderStdCode) ?? userStdCode,
    assigned_stores: manageWalletModalIsOpen
      ? selectedManageFilter.id !== 0
        ? selectedManageFilter.id === 1
        : undefined
      : currentTab === Tab.AssignedTabs,
    ...(debouncedSearchStore?.length && { search: debouncedSearchStore }),
    ...(debouncedSearchManageStore?.length && { search: debouncedSearchManageStore })
  })

  const storesData = useMemo(() => mergeStoresInfiniteQuery(storesListData), [storesListData])

  const storesTotalItems = storesListData?.pages[0].data.metadata.pagination.total ?? 0

  const onEndReachedStores = useCallback(() => {
    if (!storesListIsFetchingNextPage && storesListHasNextPage) {
      storesListFetchNextPage()
    }
  }, [storesListIsFetchingNextPage, storesListHasNextPage, storesListFetchNextPage])

  const { data: walletsManagementDetailsData, isLoading: walletsManagementDetailsIsLoading } =
    useGetWalletsManagementDetailsQuery({
      leader_std_code: Number(leaderStdCode) ?? userStdCode
    })

  const detailsData = walletsManagementDetailsData?.data.data

  const { mutate: concludeMutate, isLoading: concludeIsLoading } =
    useConcludeWalletsManagementMutation()

  const { mutate: manageWalletMutate, isLoading: manageWalletIsLoading } = useManageWalletMutation()

  const { mutate: flagAsExternalMutate, isLoading: flagAsExternalIsLoading } =
    useFlagAsExternalMutation()

  const handleTabChange = (tab: Tab) => {
    setCurrentTab(tab)
  }

  const {
    isOpen: concludeModalIsOpen,
    onClose: concludeModalOnClose,
    onOpen: concludeModalOnOpen
  } = useDisclose()

  const {
    isOpen: confirmManageWalletModalIsOpen,
    onClose: confirmManageWalletModalOnClose,
    onOpen: confirmManageWalletModalOnOpen
  } = useDisclose()

  const {
    isOpen: reactivateModalIsOpen,
    onClose: reactivateModalOnClose,
    onOpen: reactivateModalOnOpen
  } = useDisclose()

  const toast = useToast()

  const handleConclude = useCallback(() => {
    const clearAllTimeouts = () => {
      timeouts.forEach((timeoutId) => clearTimeout(timeoutId))

      setTimeouts([])
    }

    concludeMutate(
      { leader_std_code: leaderStdCode ? Number(leaderStdCode) : userStdCode, stores: undefined },
      {
        onError() {
          const timeoutId = window.setTimeout(() => {
            concludeModalOnClose()

            clearAllTimeouts()
          }, 1000)

          setTimeouts((prev) => [...prev, timeoutId])

          toast.show({
            render: () => <Toast type="error" text="Não foi possível completar a ação " />,
            placement: 'bottom',
            duration: 1500
          })
        },
        onSuccess() {
          const timeoutId = window.setTimeout(() => {
            concludeModalOnClose()

            clearAllTimeouts()
          }, 1000)

          setTimeouts((prev) => [...prev, timeoutId])

          toast.show({
            render: () => <Toast type="success" text="Carteirização concluída 🎉" />,
            placement: 'bottom',
            duration: 1500
          })
        }
      }
    )
  }, [concludeMutate, concludeModalOnClose, leaderStdCode, userStdCode, toast, timeouts])

  const handleFlagAsExternal = useCallback(
    (tab_number: number, status: boolean) => {
      const clearAllTimeouts = () => {
        timeouts.forEach((timeoutId) => clearTimeout(timeoutId))

        setTimeouts([])
      }

      flagAsExternalMutate(
        {
          leaderStdCode: leaderStdCode ? Number(leaderStdCode) : userStdCode,
          storeTabNumber: tab_number,
          status
        },
        {
          onError() {
            const timeoutId = window.setTimeout(() => {
              clearAllTimeouts()
            }, 1000)

            setTimeouts((prev) => [...prev, timeoutId])

            toast.show({
              render: () => <Toast type="error" text="Não foi possível completar a ação " />,
              placement: 'bottom',
              duration: 1500
            })
          },
          onSuccess() {
            const timeoutId = window.setTimeout(() => {
              clearAllTimeouts()
            }, 1000)

            setTimeouts((prev) => [...prev, timeoutId])

            toast.show({
              render: () => <Toast type="success" text="Atualização efetuada com sucesso 🎉" />,
              placement: 'bottom',
              duration: 1500
            })
          }
        }
      )
    },
    [leaderStdCode, flagAsExternalMutate, timeouts, toast, userStdCode]
  )

  const { isOpen: orderByIsOpen, onOpen: orderByOnOpen, onClose: orderByOnClose } = useDisclose()

  const handleCheckboxChange = (field: string, direction: 'asc' | 'desc') => {
    setSelectedOrderBy({ field, direction })
  }

  const handleSelectManageFilter = (filter: { id: number; name: string }) => {
    setSelectedManageFilter(filter)
  }

  const handleApply = () => {
    setAppliedOrderBy(
      selectedOrderBy?.field
        ? selectedOrderBy?.field === 'Nome'
          ? 'name'
          : 'qtde_stores'
        : undefined
    )

    setAppliedOrderByDirection(selectedOrderBy?.direction)
  }

  const handleClear = () => {
    setSelectedOrderBy({ field: undefined, direction: undefined })
  }

  useEffect(() => {
    return () => {
      timeouts.forEach((timeoutId) => clearTimeout(timeoutId))
    }
  }, [timeouts])

  const calculateTotalStores = (staffList: TStaffListItem[]): number => {
    return staffList.reduce((sum, leader) => {
      return sum + Number(leader.qtd_stores)
    }, 0)
  }

  const unassignedStores = (detailsData?.tabs?.total ?? 0) - (detailsData?.tabs?.assigned ?? 0)

  const handleGoToConclude = useCallback(() => {
    concludeModalOnClose()

    linkTo(`/carteirizacao/concluir/${leaderStdCode ?? userStdCode}`)
  }, [linkTo, leaderStdCode, userStdCode, concludeModalOnClose])

  const walletManagementIsOpen = detailsData?.status === WalletManagementStatus.ACTIVED

  const handleGoBack = () => linkTo('/carteirizacao-admin')

  const handleManageWallet = useCallback(
    (item: TManageWalletParams) => {
      const clearAllTimeouts = () => {
        timeouts.forEach((timeoutId) => clearTimeout(timeoutId))

        setTimeouts([])
      }

      manageWalletMutate(
        {
          leaderStdCode: Number(leaderStdCode) ?? userStdCode,
          storesTabNumber: item.storesTabNumber,
          userStdCode: item.userStdCode
        },
        {
          onError() {
            const timeoutId = window.setTimeout(() => {
              clearAllTimeouts()
            }, 1000)

            setTimeouts((prev) => [...prev, timeoutId])

            confirmManageWalletModalOnClose()

            resetSelectedItems()

            toast.show({
              render: () => <Toast type="error" text="Não foi possível completar a ação " />,
              placement: 'bottom',
              duration: 1500
            })
          },
          onSuccess() {
            const timeoutId = window.setTimeout(() => {
              clearAllTimeouts()
            }, 1000)

            setTimeouts((prev) => [...prev, timeoutId])

            confirmManageWalletModalOnClose()

            resetSelectedItems()

            toast.show({
              render: () => <Toast type="success" text="Carteirização concluída 🎉" />,
              placement: 'bottom',
              duration: 1500
            })
          }
        }
      )
    },
    [
      manageWalletMutate,
      leaderStdCode,
      toast,
      timeouts,
      confirmManageWalletModalOnClose,
      userStdCode
    ]
  )

  const handleGoToOpenWalletsPeriod = useCallback(() => {
    navigation.navigate('OpenWalletsPeriod', {
      leaderStdCode: String(leaderStdCode)
    })
  }, [navigation, leaderStdCode])

  const [selectedTabs, setSelectedTabs] = useState<Set<number>>(new Set())

  const handleToggleTab = (tabId: number) => {
    setSelectedTabs((prevSelectedTabs) => {
      const newSelectedTabs = new Set(prevSelectedTabs)

      if (newSelectedTabs.has(tabId)) {
        newSelectedTabs.delete(tabId)
      } else {
        newSelectedTabs.add(tabId)
      }

      return newSelectedTabs
    })
  }

  const [selectedStaff, setSelectedStaff] = useState<Partial<TStaffListItem> | undefined>()

  const handleSelectStaff = (item: Partial<TStaffListItem>) => {
    setSelectedStaff((prevStaffId) =>
      prevStaffId?.user_std_code === item.user_std_code ? undefined : item
    )
  }

  const resetSelectedItems = () => {
    setSelectedStaff(undefined)

    setSelectedTabs(new Set())
  }

  return {
    isMobile,
    currentTab,
    staffData,
    staffListIsLoading,
    storesData,
    storesListIsLoading,
    detailsData,
    detailsIsLoading: walletsManagementDetailsIsLoading,
    handleTabChange,
    concludeModalIsOpen,
    concludeModalOnClose,
    concludeModalOnOpen,
    handleConclude,
    concludeIsLoading,
    searchManageStaff,
    onSearchManageStaff,
    searchManageStore,
    onSearchManageStore,
    searchStaff,
    onSearchStaff,
    searchStore,
    onSearchStore,
    onPressSearchClear: handleSearchClear,
    handleClear,
    handleApply,
    handleCheckboxChange,
    selectedOrderBy,
    orderByIsOpen,
    orderByOnOpen,
    orderByOnClose,
    calculateTotalStores,
    manageWalletModalIsOpen,
    manageWalletModalOnClose,
    manageWalletModalOnOpen,
    unassignedStores,
    handleGoToConclude,
    walletManagementIsOpen,
    isAdmin,
    handleGoBack,
    handleManageWallet,
    confirmManageWalletModalIsOpen,
    confirmManageWalletModalOnClose,
    confirmManageWalletModalOnOpen,
    manageWalletIsLoading,
    reactivateModalIsOpen,
    reactivateModalOnClose,
    reactivateModalOnOpen,
    handleGoToOpenWalletsPeriod,
    onEndReachedStaff,
    onEndReachedStores,
    staffTotalItems,
    storesTotalItems,
    staffListIsFetchingNextPage,
    storesListIsFetchingNextPage,
    manageFilters,
    selectedManageFilter,
    handleSelectManageFilter,
    handleFlagAsExternal,
    flagAsExternalIsLoading,
    handleSelectStaff,
    handleToggleTab,
    selectedTabs,
    selectedStaff
  }
}
