import { useCallback, useMemo, useState } from 'react'

import {
  TLeadersListItem,
  useFinishWalletsPeriodMutation,
  useGetLeadersListInfinityQuery,
  useGetWalletsManagementPeriodQuery
} from 'integration/resources/walletsManagement'
import { useBreakpointValue, useDisclose } from 'native-base'
import useDebounce from 'src/hooks/useDebounce'

import { OrderField, UseLeadersListScreen } from './LeadersListScreen.types'

export const orderFields: OrderField[] = [
  {
    label: 'Nome',
    options: [
      { id: 'asc', name: 'A-Z' },
      { id: 'desc', name: 'Z-A' }
    ]
  },
  {
    label: 'Número de TABs',
    options: [
      { id: 'asc', name: 'Menor para o Maior' },
      { id: 'desc', name: 'Maior para o menor' }
    ]
  }
]

const mergeLeadersInfiniteQuery = (
  data: ReturnType<typeof useGetLeadersListInfinityQuery>['data']
) =>
  data?.pages.reduce<TLeadersListItem[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useLeadersListScreen: UseLeadersListScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [search, onSearch] = useState<string>('')

  const debouncedSearch = useDebounce(search, 500)

  const handleSearchClear = () => {
    onSearch('')
  }

  const [selectedOrderBy, setSelectedOrderBy] = useState<{
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }>({
    field: undefined,
    direction: undefined
  })

  const { data: periodData, isLoading: periodIsLoading } = useGetWalletsManagementPeriodQuery()

  const adminPeriodData = periodData?.data.data

  const [appliedOrderBy, setAppliedOrderBy] = useState<
    'leader_user_name' | 'total_stores' | undefined
  >()

  const [appliedOrderByDirection, setAppliedOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >()

  const {
    data: leadersListData,
    isLoading: leadersListLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useGetLeadersListInfinityQuery({
    order_by: appliedOrderBy,
    order_by_direction: appliedOrderByDirection,
    ...(debouncedSearch?.length && { search: debouncedSearch })
  })

  const leadersList = useMemo(() => mergeLeadersInfiniteQuery(leadersListData), [leadersListData])

  const totalItems = leadersListData?.pages[0].data.metadata.pagination.total ?? 0

  const unassignedSpecialists = Number(adminPeriodData?.total_leader_active) ?? 0

  const onEndReached = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const { isOpen: orderByIsOpen, onOpen: orderByOnOpen, onClose: orderByOnClose } = useDisclose()

  const { mutate: finishWalletsPeriod, isLoading: finishWalletsPeriodIsLoading } =
    useFinishWalletsPeriodMutation()

  const handleFinishPeriod = () => {
    adminPeriodData?.id && finishWalletsPeriod({ period_id: adminPeriodData?.id })
  }

  const handleCheckboxChange = (field: string, direction: 'asc' | 'desc') => {
    setSelectedOrderBy({ field, direction })
  }

  const handleApply = () => {
    setAppliedOrderBy(
      selectedOrderBy?.field
        ? selectedOrderBy?.field === 'Nome'
          ? 'leader_user_name'
          : 'total_stores'
        : undefined
    )

    setAppliedOrderByDirection(selectedOrderBy?.direction)
  }

  const handleClear = () => {
    setSelectedOrderBy({ field: undefined, direction: undefined })
  }

  const handleGoToStaffList = (userStdCode: string) => {
    navigation.navigate('StaffList', { userStdCode })
  }

  const handleGoToOpenWalletsPeriod = () => {
    navigation.navigate('OpenWalletsPeriod', {})
  }

  return {
    isMobile,
    leadersListData: leadersList,
    leadersListIsLoading: leadersListLoading,
    leadersListIsFetchingNextPage: isFetchingNextPage,
    totalItems,
    onEndReached,
    search,
    onSearch,
    onPressSearchClear: handleSearchClear,
    handleClear,
    handleApply,
    handleCheckboxChange,
    selectedOrderBy,
    orderByIsOpen,
    orderByOnOpen,
    orderByOnClose,
    handleGoToStaffList,
    handleGoToOpenWalletsPeriod,
    handleFinishPeriod,
    finishWalletsPeriodIsLoading,
    adminPeriodData,
    periodIsLoading,
    unassignedSpecialists
  }
}
