import { OpportunityType, TNetworks, TUsers } from 'integration/resources/newOpportunities'
import * as yup from 'yup'

import { TOption, TOptionCommon } from './OpportunityFilterScreen.types'
import { ListType } from '../../OrganizationScreen.types'

export const getOptionLabel = (option: TOption) =>
  typeof option === 'string'
    ? option
    : (option as TOptionCommon)?.name ??
      (option as TNetworks)?.network ??
      (option as TUsers)?.user_name

export const OPPORTUNITY_TYPES_OPTIONS = [
  {
    id: OpportunityType.ODC,
    name: 'Oportunidades'
  },
  {
    id: OpportunityType.ACTIVE_BASE,
    name: 'Base Ativa'
  }
]

export const BaseFormSchema = yup.object().shape({
  segment: yup.string(),
  network: yup.string(),
  subsidiary: yup.string(),
  user_std_code: yup.string()
})

export const ChargeFormSchema = BaseFormSchema

export const ODCFormSchema = BaseFormSchema.concat(
  yup.object().shape({
    offer: yup.string(),
    public_rating: yup.string()
  })
)

export const ActiveBaseFormSchema = BaseFormSchema.concat(
  yup.object().shape({
    public_rating: yup.string()
  })
)

export const InsuranceFormSchema = BaseFormSchema

export const HighlightedFormSchema = BaseFormSchema.concat(
  yup.object().shape({
    opportunity_type: yup.string()
  })
)

export const ConcludedFormSchema = BaseFormSchema.concat(
  yup.object().shape({
    opportunity_type: yup.string()
  })
)

export const PendingFormSchema = BaseFormSchema.concat(
  yup.object().shape({
    opportunity_type: yup.string()
  })
)

export const OPPORTUNITY_FILTER_SCHEMAS = {
  [ListType.ODC]: ODCFormSchema,
  [ListType.ActiveBase]: ActiveBaseFormSchema,
  [ListType.Insurance]: InsuranceFormSchema,
  [ListType.Highlighted]: HighlightedFormSchema,
  [ListType.Charge]: ChargeFormSchema,
  [ListType.Concluded]: ConcludedFormSchema,
  [ListType.Pending]: PendingFormSchema
}

export const returnOpportunityType = (listType: ListType) => {
  switch (Number(listType)) {
    case ListType.ODC:
      return OpportunityType.ODC

    case ListType.ActiveBase:
      return OpportunityType.ACTIVE_BASE

    case ListType.Insurance:
      return OpportunityType.INSURANCE

    case ListType.Highlighted:
      return OpportunityType.HIGHLIGHTED

    case ListType.Charge:
      return OpportunityType.CHARGE

    case ListType.Concluded:
      return OpportunityType.CONCLUDED

    case ListType.Pending:
      return OpportunityType.PENDING

    default:
      return OpportunityType.ODC
  }
}
