import { LinkingOptions, PathConfigMap } from '@react-navigation/native'
import * as Linking from 'expo-linking'

import { RootStackParamList } from './types'

const isDev = process.env.Environment !== 'prd'

const screens: PathConfigMap<RootStackParamList> = {
  Entry: {
    path: '/'
  },
  SSOAuth: {
    path: '/sso-auth'
  },
  CreateAccountCPF: {
    path: '/cadastro/cpf'
  },
  CreateAccountPhoneEmail: {
    path: 'cadastro/email'
  },
  Login: {
    path: '/login'
  },
  LoginAuthCode: {
    path: '/autenticacao'
  },
  LoginAccountReview: {
    path: '/login/analise'
  },
  LoginBlocked: {
    path: '/login/conta-bloqueada'
  },
  AcceptTerms: {
    path: '/termos-de-aceite'
  },
  Onboarding: {
    path: '/bem-vindo'
  },
  CreateAccountConfirmEmail: {
    path: '/cadastro/email/confirmacao/processando'
  },
  CreateAccountCreatePassword: {
    path: '/cadastro/senha'
  },
  CreateAccountAuthCode: {
    path: '/cadastro/codigo'
  },
  CreateAccountChangeEmail: {
    path: '/cadastr/alterar-email'
  },
  CreateAccountSendEmailConfirmation: {
    path: '/cadastro/email/confirmacao/enviado'
  },
  ContentByCategory: {
    path: '/conteudos/categoria/:category_id'
  },
  ResetPasswordSendEmail: {
    path: '/redefinir-senha/email'
  },
  ResetPasswordCreatePassword: {
    path: '/redefinir-senha/senha'
  },
  ResetPasswordReSentEmail: {
    path: '/redefinir-senha/email/reenviado'
  },
  ResetPasswordEmailSentSuccessfully: {
    path: '/redefinir-senha/email/enviado'
  },
  ResetPasswordSuccessfully: {
    path: '/redefinir-senha/feedback'
  },
  ResetPasswordAuthCode: {
    path: '/redefinir-senha/codigo'
  },
  Simulator: {
    path: '/simulator'
  },
  Opportunity: {
    path: '/oportunidade/:title/:simulatedPoints/:total/:business_block_id'
  },
  OpportunityDetails: {
    path: '/oportunidade/:id/:type'
  },
  NotificationDetail: {
    path: '/notificacao/:id/detalhes'
  },
  FirstLoginPassword: {
    path: '/primeiro-acesso'
  },
  Content: {
    path: '/conteudos/:id/detalhes'
  },
  SummaryDetail: {
    path: '/extrato'
  },
  Chat: {
    path: '/chat'
  },
  ContractList: {
    path: '/contratos/:tabNumber/:yearMonth/:type'
  },
  WalletContact: {
    path: '/carteira/contatos/:contactId'
  },
  OrganizationActivity: {
    path: 'minha-organizacao/tarefa/:id'
  },
  OrganizationActivityEdit: {
    path: 'minha-organizacao/tarefa/:id/editar'
  },
  OrganizationActivityCreate: {
    path: 'minha-organizacao/tarefa/criar'
  },
  OrganizationOpportunity: {
    path: 'oportunidade/:id'
  },
  OrganizationOpportunityList: {
    path: '/minha-organizacao/oportunidades/:listType'
  },
  OpportunityFilter: {
    path: '/minha-organizacao/oportunidades/:listType/filtros'
  },
  OpportunityOrderByFilter: {
    path: '/minha-organizacao/oportunidades/:listType/ordenar'
  },
  OrganizationActivityList: {
    path: '/minha-organizacao/tarefas'
  },
  WalletTabList: {
    path: '/carteira/tabs'
  },
  WalletTab: {
    path: '/carteira/tabs/:tabNumber'
  },
  TabDetails: {
    path: '/carteira/tabs/:tabNumber/dados'
  },
  TabContact: {
    path: '/carteira/tabs/:tabNumber/contatos'
  },
  TabFilter: {
    path: '/carteira/tabs/filtros'
  },
  TabLocation: {
    path: '/carteira/tabs/:tabNumber/localizacao'
  },
  TabRelated: {
    path: '/carteira/tabs/:tabNumber/relacionadas'
  },
  TabEditNoteDetails: {
    path: '/carteira/tabs/:tabNumber/notas/editar'
  },
  TabCreateNoteDetails: {
    path: '/carteira/tabs/:tabNumber/notas/criar'
  },
  ContactFilter: {
    path: '/carteira/contatos/filtros'
  },
  ContactDetails: {
    path: '/carteira/contatos/:contactId/dados'
  },
  ContactBirthday: {
    path: '/carteira/contatos/:contactId/aniversario'
  },
  ContactEditBirthday: {
    path: '/carteira/contatos/:contactId/aniversario/editar'
  },
  ContactEditNoteDetails: {
    path: '/carteira/contatos/:contactId/notas/editar'
  },
  ContactCreateNoteDetails: {
    path: '/carteira/contatos/:contactId/notas/criar'
  },
  ContactCreate: {
    path: '/carteira/contatos/criar'
  },
  WalletContactList: {
    path: '/carteira/contatos'
  },
  ContactEdit: {
    path: '/carteira/contatos/:contactId/editar'
  },
  TabEdit: {
    path: '/carteira/tabs/:tabNumber/editar'
  },
  ProductionChart: {
    path: '/carteira/producao'
  },
  Production: {
    path: '/producao'
  },
  Chart: {
    path: '/carteira/resumo/:tabNumber/:type'
  },
  ErrorPageNotFound: {
    path: '/*'
  },
  ErrorPageErrorOccurred: {
    path: '/ocorreu-um-erro'
  },
  OptionsProfile: {
    path: 'perfil/opcoes'
  },
  PreferencesProfile: {
    path: '/perfil/preferencias'
  },
  SecurityProfile: {
    path: '/perfil/seguranca-e-privacidade'
  },
  RemoveAccountConfirm: {
    path: '/perfil/apagar-conta'
  },
  About: {
    path: '/perfil/sobre'
  },
  EditProfile: {
    path: '/perfil/editar'
  },
  Dashboard: {
    path: '/paineis'
  },
  DashboardDetail: {
    path: '/paineis/detalhes'
  },
  Survey: {
    path: '/pesquisas'
  },
  SurveyDetail: {
    path: '/pesquisas/detalhes'
  },
  LeadsApnef: {
    path: '/leads-apnef/:opportunityId/:type'
  },
  Recommendations: {
    path: '/recomendacoes/:opportunityId'
  },
  HirePlus: {
    path: '/contrata-ja'
  },
  Tab: {
    screens: {
      Home: '/home',
      Notification: '/notificacoes',
      Attendance: '/atendimento',
      HomeContent: '/conteudos',
      Profile: '/perfil',
      Organization: '/minha-organizacao',
      Wallet: '/carteira'
    }
  }
}

if (isDev) {
  screens.GoalsLeadersList = {
    path: '/metas-admin'
  }

  screens.GoalsLeaderDetails = {
    path: '/metas-lider'
  }

  screens.LeadersList = {
    path: '/carteirizacao-admin'
  }

  screens.StaffList = {
    path: '/carteirizacao-lider'
  }

  screens.ConcludeManagement = {
    path: '/carteirizacao/concluir/:userStdCode'
  }

  screens.OpenWalletsPeriod = {
    path: '/carteirizacao-admin/abrir-periodo'
  }

  screens.ConcludeManagement = {
    path: '/carteirizacao/concluir/:userStdCode'
  }

  screens.OpenWalletsPeriod = {
    path: '/carteirizacao-admin/abrir-periodo'
  }
}

export const linking: LinkingOptions<RootStackParamList> = {
  config: {
    initialRouteName: 'Entry',
    screens
  },
  prefixes: [
    Linking.createURL('/'),
    'https://www.portalmaisturbo.com.br/',
    'https://hml.portalmaisturbo.com.br/',
    'portalmaisturbo://'
  ]
}
