export type TParams = {
  leader_std_code?: number
  page?: number
  per_page?: number
  order_by?: string
  order_by_direction?: string
  search?: string
  enabled?: boolean
  assigned_stores?: boolean
}

export type TConcludeParams = {
  leader_std_code: number
  stores?: {
    store_tab_number: number
    description: string
  }[]
}

export enum WalletManagementStatus {
  ACTIVED = 1,
  CONCLUDED = 2,
  REACTIVED = 3,
  CLOSED = 4
}

export enum PeriodStatus {
  OPENED = 1,
  CLOSED = 2
}

export const WalletManagementStatusLabels: { [key in WalletManagementStatus]: string } = {
  [WalletManagementStatus.ACTIVED]: 'Ativo',
  [WalletManagementStatus.CLOSED]: 'Fechado',
  [WalletManagementStatus.REACTIVED]: 'Ativo',
  [WalletManagementStatus.CONCLUDED]: 'Concluído'
}

export type TStaffListItem = {
  user_std_code: number
  user_name: string
  occupation: string
  qtd_stores: string
  profile_image_file_name: string
}

export type TLeadersListItem = {
  leader_std_code: number
  leader_user_name: string
  occupation: string
  profile_image_file_name?: string
  network: {
    network_code?: string
    network_name?: string
  }
  subsidiary: {
    subsidiary_name?: string
    subsidiary_code?: string
  }
  status: WalletManagementStatus
  openingDate: string
  closingDate: string
  total_specialists: number
  total_stores: number
}

export type TStoresListItem = {
  tab_number: string
  name_fantasy: string
  logo_image_file_name?: string
  segment_name: string
  subsegment_name: string
  is_store_without_subsidiary: boolean
  assigned_to_user_std_code: number
  assigned_to_user_name: string
  assigned_by_user_std_code?: number
  assigned_by_user_name?: string
  assigned_at: string
}

export type TWalletsManagementDetails = {
  started_at: string
  finished_at: string
  leader_std_code: number
  leader_user_name: string
  occupation: string
  profile_image_file_name?: string
  network: {
    network_code: string
    network_name: string
  }
  subsidiary: {
    subsidiary_name: string
    subsidiary_code: string
  }
  status: WalletManagementStatus
  specialists: {
    total: number
    assigned: number
  }
  tabs: {
    total: number
    assigned: number
  }
  leader_infos?: {
    user_name: string
    occupation: string
    network: string
    user_std_code: string
    subsidiary: string
  }
}

export type TManageWalletParams = {
  leaderStdCode: number
  userStdCode: string
  storesTabNumber: string[]
}

export type TOpenWalletsPeriodParams = {
  date_start: string
  date_end: string
  leader_std_code?: number
}

export type TWalletsManagementPeriod = {
  id: string
  year_month: number
  period_type_enum: PeriodType
  status: PeriodStatus
  started_at: string
  finished_at: string
  createdAt: string
  updatedAt: string
  total_leader_active: string
}

export enum PeriodType {
  GENERAL = 1,
  LEADER = 2
}

export type TStoreWithoutSubsidiaryParams = {
  leaderStdCode: number
  storeTabNumber: number
  status: boolean
}
