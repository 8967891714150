import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Cards, Icons, LoadingTurbo } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { LinearGradient } from 'expo-linear-gradient'
import { ButtonFilter, UnconnectedMessage } from 'molecules'
import { FavoritListCard } from 'molecules/FavoritListCard'
import { Box, VStack, Text, Heading, HStack, Center, Button, ZStack, useTheme } from 'native-base'
import { Platform, useWindowDimensions } from 'react-native'
import { opportunityViewTypes } from 'src/screens/OrganizationScreen/useOrganizationScreen'

import { useODC } from './hook'
import { ListTypesRef, ListTypesView } from '../../../OrganizationScreen.types'

export const ODC = forwardRef<ListTypesRef, ListTypesView>(
  ({ navigation, route, handleSelect, isLoading }, ref) => {
    const { width, height } = useWindowDimensions()

    const theme = useTheme()

    const [search, setSearch] = useState('')

    const {
      opportunitiesList,
      isLoading: localIsLoading,
      isMobile,
      fetchMore,
      isFetchingNextPage,
      refetch,
      refreshing,
      handleGoToDetails,
      handleHighlight,
      isEmpty,
      highlights,
      highlightsIsEmpty,
      handleConclude,
      highlightIsLoading,
      concludeIsLoading,
      totalItems,
      handleGoToFilters,
      activeFilterCount
    } = useODC({ search, navigation, route })

    const hasFilter = !!activeFilterCount && activeFilterCount > 0

    const skeletonArray = new Array(6).fill(0)

    useImperativeHandle(ref, () => ({
      fetchMore,
      refetch,
      refreshing,
      search: (value: string) => {
        setSearch(value)
      },
      clearSearch: () => setSearch('')
    }))

    useEffect(() => {
      navigation.addListener('focus', () => {
        refetch()
      })
    }, [navigation, refetch])

    // @TODO limpar search quando alterar os filtros
    return (
      <VStack mt={10} mx={{ base: 4, lg: 0 }}>
        <Box zIndex={0}>
          {localIsLoading && (
            <VStack py={4} w={width - 32}>
              {skeletonArray.map((_, i) => (
                <SkeletonCard key={`key-skel-${i}`} />
              ))}
            </VStack>
          )}
          {!isMobile && !localIsLoading && (
            <HStack justifyContent="space-between" mb={8}>
              <VStack>
                <Heading fontWeight="bold" lineHeight="38px" fontSize="24px">
                  Oportunidades
                </Heading>
                <Text color="gray.400" fontSize="16px" fontWeight="normal">
                  {totalItems} {`Oportunidade${Number(totalItems) === 1 ? '' : 's'}`}
                </Text>
              </VStack>
              <HStack space={6} position="relative">
                {/* TEMPORARILY DISABLED BY CUSTOMER’S DECISION */}
                {/* <DropdownOrderBy navigation={navigation} route={route} /> */}

                <ButtonFilter
                  hasFilter={hasFilter}
                  activeFilterCount={activeFilterCount}
                  onPress={() => handleGoToFilters()}
                />
              </HStack>
            </HStack>
          )}
          {isEmpty && !localIsLoading && <UnconnectedMessage />}

          {Platform.OS !== 'web' && !highlightsIsEmpty && (
            <FavoritListCard
              title="Destaques"
              type={`Oportunidade${highlights.length && highlights.length > 1 ? 's' : ''}`}
              quantity={highlights.length ?? 0}>
              <>
                {highlights.slice(0, 3).map((opportunity) => (
                  <Cards.OpportunitiesList
                    handleConclude={handleConclude}
                    concludeIsLoading={concludeIsLoading}
                    handleGoToDetails={handleGoToDetails}
                    handleHighlight={handleHighlight}
                    highlightIsLoading={highlightIsLoading}
                    opportunity={opportunity}
                    isMobile
                    key={`card-${opportunity.id}`}
                  />
                ))}

                {highlights.length > 3 && (
                  <Button
                    pb={0}
                    colorScheme="gray"
                    bgColor="transparent"
                    rightIcon={<Icons.ArrowRightGo />}
                    onPress={() => {
                      handleSelect(opportunityViewTypes[4])
                    }}>
                    <Text color="white">Ver todos os destaques</Text>
                  </Button>
                )}
              </>
            </FavoritListCard>
          )}

          {!isEmpty && isMobile && (
            <HStack justifyContent="space-between" mb={4}>
              <Heading fontSize="24px" fontWeight={700} lineHeight="28px" color="gray.700">
                Oportunidades
              </Heading>

              <Center bgColor="white" px={4} borderRadius="8px">
                <Heading fontSize="12px" fontWeight={700} color="gray.400">
                  {totalItems} {`OPORTUNIDADE${Number(totalItems) === 1 ? '' : 'S'}`}
                </Heading>
              </Center>
            </HStack>
          )}

          {!isEmpty &&
            opportunitiesList?.map((opportunity, index) => (
              <Cards.OpportunitiesList
                handleConclude={handleConclude}
                concludeIsLoading={concludeIsLoading}
                handleGoToDetails={handleGoToDetails}
                handleHighlight={handleHighlight}
                highlightIsLoading={highlightIsLoading}
                opportunity={opportunity}
                isMobile={isMobile}
                key={`cardList-${opportunity.id}`}
              />
            ))}

          {isFetchingNextPage && (
            <Center mt={6}>
              <LoadingTurbo size={48} />
            </Center>
          )}

          {refreshing && !isLoading && (
            <>
              <LinearGradient
                style={{
                  position: 'absolute',
                  height: 800,
                  zIndex: 4,
                  flex: 1,
                  width
                }}
                // @ts-ignore
                colors={['#ffffff05', theme.colors.background.light, '#ffffff05']}
              />

              <ZStack
                position="absolute"
                w={width - 32}
                h={0}
                alignItems="center"
                justifyContent="center"
                top={height / 5}
                zIndex={8}>
                <Center>
                  <LoadingTurbo size={48} />
                </Center>
              </ZStack>
            </>
          )}
        </Box>
      </VStack>
    )
  }
)
