import { FunctionComponent, useMemo, useState } from 'react'

import Icons from 'atoms/Icons'
import {
  HandleFinishParams,
  OPPORTUNITIES_TYPES_NAMES,
  OpportunitiesItem,
  OpportunitiesTypes,
  OpportunityType
} from 'integration/resources/newOpportunities'
import {
  VStack,
  Heading,
  Text,
  HStack,
  Circle,
  Box,
  Center,
  Button,
  useDisclose,
  Skeleton
} from 'native-base'
import { TouchableOpacity } from 'react-native'
import { formatCNPJ } from 'src/utils'
import { changeToCurrency } from 'src/utils/currencyFormat'

import { ChargeModal } from './ChargeModal'

type OpportunitiesListCardProps = {
  opportunity: OpportunitiesItem
  isMobile: boolean
  handleGoToDetails?(opportunityId: string, itemType?: OpportunityType): void
  handleConclude?(data: HandleFinishParams): void
  handleHighlight?(id: string, highlighted: boolean, opportunity_type_enum?: OpportunityType): void
  maxWidth?: number
  bgIsWhite?: boolean
  highlightIsLoading?: string
  concludeIsLoading?: string
}

export const switchBgColor = (type: OpportunitiesTypes, isOpen?: boolean) => {
  switch (type) {
    case OpportunitiesTypes.RISK_OF_LOSS:
      return '#FFCC1F'

    case OpportunitiesTypes.MORE_CONVERSION:
      return '#EC0000'

    case OpportunitiesTypes.POWERED:
      return '#0D0D0D'

    case OpportunitiesTypes.POWERED2:
      return '#0D0D0D'

    case OpportunitiesTypes.VIEW_MORE:
      return '#8A2648'

    case OpportunitiesTypes.EFFECTIVE:
      return '#306946'

    case OpportunitiesTypes.APNEF:
      return '#306946'

    case OpportunitiesTypes.ACCELERATES_RTG10:
      return '#A2702A'

    case OpportunitiesTypes.ACCREDITATE:
      return '#9BC3D3'

    case OpportunitiesTypes.RE_CREDIT:
      return '#6F7779'

    case OpportunitiesTypes.TURBO_PLAN:
      return '#0D0D0D'

    default:
      return isOpen ? 'white' : 'gray.50'
  }
}

type callsignComponentProps = {
  isCharge: boolean
  opportunity: OpportunitiesItem
}

const callsignComponent = ({ isCharge, opportunity }: callsignComponentProps) => {
  const label = isCharge ? 'Saldo Devedor' : opportunity.callsigns?.[0]?.name ?? ''

  const value = isCharge
    ? changeToCurrency(opportunity.store?.debit_balance ?? 0)
    : changeToCurrency(opportunity.callsigns?.[0]?.value ?? 0)

  return (
    <HStack space={2}>
      <Text fontSize="12px" fontWeight={400} lineHeight="16px" color="gray.400">
        {label}:
      </Text>
      <Text fontSize="12px" fontWeight={400} lineHeight="16px" color="gray.700">
        {value}
      </Text>
    </HStack>
  )
}

export const OpportunitiesListCard: FunctionComponent<OpportunitiesListCardProps> = ({
  handleConclude,
  handleGoToDetails,
  handleHighlight,
  opportunity,
  isMobile,
  highlightIsLoading,
  concludeIsLoading,
  maxWidth = 1122,
  bgIsWhite = false
}) => {
  const [expandDetails, setExpandDetails] = useState('')

  const { isOpen, onClose, onOpen } = useDisclose(false)

  const isInsurance = useMemo(
    () => opportunity.opportunity_type_enum === OpportunityType.INSURANCE,
    [opportunity.opportunity_type_enum]
  )

  const isCharge = useMemo(
    () =>
      !!opportunity.contract_infos?.contract_number ||
      opportunity.opportunity_type_enum === OpportunityType.CHARGE,
    [opportunity]
  )

  const isActiveBase = useMemo(
    () => opportunity.opportunity_type_enum === OpportunityType.ACTIVE_BASE,
    [opportunity]
  )

  const handleExpandDetails = (id: string) => {
    return isMobile && isCharge
      ? onOpen()
      : expandDetails === id
      ? setExpandDetails('')
      : setExpandDetails(id)
  }

  return isMobile ? (
    <>
      <TouchableOpacity
        onPress={() =>
          isCharge
            ? handleExpandDetails(opportunity.id)
            : handleGoToDetails?.(opportunity.id, opportunity.opportunity_type_enum)
        }
        key={`${opportunity.id}`}
        style={{ marginBottom: 8 }}>
        <HStack bgColor={bgIsWhite ? 'gray.50' : 'white'} borderRadius="20px" p={4} space={4}>
          <Center>
            {concludeIsLoading === opportunity.id ? (
              <Skeleton borderRadius="full" size={12} />
            ) : (
              <TouchableOpacity
                onPress={() =>
                  handleConclude?.({
                    id: opportunity.id,
                    finished: !!opportunity.is_finished,
                    only_finish: true,
                    opportunity_type_enum: opportunity.opportunity_type_enum
                  })
                }
                disabled={isInsurance || isCharge}>
                <Circle
                  bg={opportunity.is_finished ? 'secondary.50' : 'white'}
                  borderWidth={opportunity.is_finished ? 0 : '1px'}
                  borderColor="background.base"
                  size={12}>
                  <Icons.Dollar
                    size={24}
                    color={opportunity.is_finished ? 'secondary.500' : 'gray.700'}
                  />
                </Circle>
              </TouchableOpacity>
            )}
          </Center>

          <VStack space={1} flex={1}>
            <Heading fontSize="14px" fontWeight={700} lineHeight="18px" color="gray.700">
              {isCharge
                ? opportunity.contract_infos?.contract_number
                : opportunity.store.name_fantasy}
            </Heading>

            <HStack>
              {opportunity.store.tab_number && (
                <Text fontSize="12px" fontWeight={400} lineHeight="16px" color="gray.400">
                  TAB {opportunity.store.tab_number}
                </Text>
              )}
              {opportunity.store.subsegment_name && (
                <Text fontSize="12px" fontWeight={400} lineHeight="16px" color="gray.400">
                  {opportunity.store.tab_number ? ' • ' : ''}
                  {opportunity.store.subsegment_name}
                </Text>
              )}
            </HStack>

            {callsignComponent({ isCharge, opportunity })}

            {isCharge ? (
              <Center w="120px" h="24px" borderRadius="8px" bgColor="gray.50">
                <Heading fontSize="12px" fontWeight={700} color="gray.400">
                  COBRANCA
                </Heading>
              </Center>
            ) : (
              !!opportunity.public_rating &&
              opportunity.public_rating !== undefined && (
                <Center
                  w="120px"
                  h="24px"
                  borderRadius="8px"
                  bgColor={switchBgColor(opportunity.public_rating, isOpen || bgIsWhite)}>
                  <Heading
                    fontSize="12px"
                    fontWeight={700}
                    color={
                      [OpportunitiesTypes.CHARGE].includes(opportunity.public_rating)
                        ? 'gray.400'
                        : 'white'
                    }>
                    {OPPORTUNITIES_TYPES_NAMES[opportunity.public_rating]}
                  </Heading>
                </Center>
              )
            )}
          </VStack>
        </HStack>
      </TouchableOpacity>

      <ChargeModal
        handleGoToDetails={handleGoToDetails}
        isOpen={isOpen}
        onClose={onClose}
        opportunity={opportunity}
      />
    </>
  ) : (
    <TouchableOpacity
      {...(!isCharge && {
        onPress: () =>
          handleGoToDetails?.(
            isCharge ? opportunity?.opportunity_odc_id ?? '' : opportunity?.id ?? '',
            opportunity.opportunity_type_enum
          )
      })}
      style={{ maxWidth, marginBottom: 16 }}
      key={`opportunity-${opportunity.id}`}>
      <VStack bgColor="white" borderRadius="20px" p={6} justifyContent="center" space={6}>
        <HStack justifyContent="space-between" alignItems="center">
          <HStack space={6} alignItems="center" h="100%">
            {concludeIsLoading === opportunity.id ? (
              <Skeleton borderRadius="full" size={12} />
            ) : (
              <TouchableOpacity
                onPress={() =>
                  handleConclude?.({
                    id: opportunity.id,
                    finished: !!opportunity.is_finished,
                    only_finish: true,
                    opportunity_type_enum: opportunity.opportunity_type_enum
                  })
                }
                disabled={isInsurance || isCharge}>
                <Circle
                  bg={opportunity.is_finished ? 'secondary.50' : 'white'}
                  borderWidth={opportunity.is_finished ? 0 : '1px'}
                  borderColor="background.base"
                  size={12}>
                  <Icons.Dollar
                    size={24}
                    color={opportunity.is_finished ? 'secondary.500' : 'gray.700'}
                  />
                </Circle>
              </TouchableOpacity>
            )}

            <VStack
              width={
                isActiveBase
                  ? '174px'
                  : !isCharge
                  ? opportunity.store.tab_number
                    ? '282px'
                    : '174px'
                  : undefined
              }>
              <HStack space={2}>
                <Text
                  isTruncated
                  maxWidth="170px"
                  fontSize="16px"
                  fontWeight={700}
                  lineHeight="24px"
                  color="gray.700">
                  {isCharge
                    ? opportunity.contract_infos?.contract_number
                    : opportunity.store.name_fantasy}
                </Text>
                {opportunity.store.tab_number && (
                  <Text fontSize="16px" fontWeight={700} lineHeight="24px" color="gray.700">
                    •{' '}
                    <Text fontFamily="OpenSans_400Regular">TAB {opportunity.store.tab_number}</Text>
                  </Text>
                )}
              </HStack>

              <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                {isCharge ? 'Contrato' : opportunity.store.subsegment_name}
              </Text>
            </VStack>

            <Box height="100%" w="1px" bgColor="gray.50" />

            {isCharge ? (
              <>
                <VStack>
                  <HStack space={2}>
                    <Text fontSize="16px" fontWeight={400} color="gray.700">
                      {changeToCurrency(opportunity.store?.debit_balance ?? 0)}
                    </Text>
                  </HStack>

                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Saldo devedor
                  </Text>
                </VStack>
                <VStack>
                  <HStack space={2}>
                    <Text fontSize="16px" fontWeight={400} color="gray.700">
                      {opportunity.store?.overdue_days}
                    </Text>
                  </HStack>

                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Dias em atraso
                  </Text>
                </VStack>
              </>
            ) : (
              opportunity.callsigns?.length &&
              opportunity.callsigns?.map((item, index) => (
                <VStack key={`callsign-${index}`}>
                  <HStack space={2}>
                    <Text fontSize="16px" fontWeight={400} color="gray.700">
                      {`${
                        item.name.includes('Dias')
                          ? item.value
                          : `${item.value < 0 ? '-' : ''}${changeToCurrency(item.value)}`
                      }`}
                    </Text>
                  </HStack>

                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    {item.name}
                  </Text>
                </VStack>
              ))
            )}
          </HStack>

          <HStack space={4} alignItems="center">
            {isCharge ? (
              <Center px={4} h="32px" borderRadius="8px" bgColor="gray.50">
                <Heading fontSize="12px" fontWeight={700} color="gray.400">
                  COBRANCA
                </Heading>
              </Center>
            ) : (
              !!opportunity.public_rating &&
              opportunity.public_rating !== undefined && (
                <Center
                  px={4}
                  h="32px"
                  bgColor={switchBgColor(opportunity.public_rating, isOpen)}
                  borderRadius="8px">
                  <Heading
                    fontSize="12px"
                    fontWeight={700}
                    color={
                      [OpportunitiesTypes.CHARGE].includes(opportunity.public_rating)
                        ? 'gray.400'
                        : 'white'
                    }>
                    {OPPORTUNITIES_TYPES_NAMES[opportunity.public_rating]}
                  </Heading>
                </Center>
              )
            )}

            {!(isInsurance || isCharge) &&
              (highlightIsLoading === opportunity.id ? (
                <Skeleton borderRadius="full" size={10} />
              ) : (
                <TouchableOpacity
                  onPress={() =>
                    handleHighlight?.(
                      opportunity.id,
                      !!opportunity.highlighted,
                      opportunity.opportunity_type_enum
                    )
                  }>
                  <Circle size={10} bgColor="gray.50">
                    <Icons.Star
                      size="20px"
                      filled={opportunity.highlighted}
                      color={opportunity.highlighted ? 'tabs-yellow.50' : 'gray.200'}
                    />
                  </Circle>
                </TouchableOpacity>
              ))}

            {opportunity.store?.store_cnpj_number && (
              <TouchableOpacity onPress={() => handleExpandDetails(opportunity.id)}>
                <Circle size={10} bgColor="gray.50">
                  <Icons.Chevron
                    size="20px"
                    direction={expandDetails === opportunity.id ? 'up' : 'down'}
                    color="gray.700"
                  />
                </Circle>
              </TouchableOpacity>
            )}
          </HStack>
        </HStack>
        {expandDetails === opportunity.id && (
          <VStack space={4}>
            <VStack space={4} bgColor="gray.30" borderRadius="10px" p={6}>
              <Text fontSize="16px" fontWeight={700} lineHeight="24px" color="gray.700">
                Dados do Contrato
              </Text>

              {/* @ts-ignore */}
              <HStack flexWrap="wrap" w="full" style={{ gap: '24px' }}>
                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    VL. Prestação
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {changeToCurrency(opportunity.contract_infos?.installment_value ?? 0)}
                  </Text>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Saldo Contábil
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {changeToCurrency(opportunity.contract_infos?.accounting_balance ?? 0)}
                  </Text>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Safra de Origem
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.contract_infos?.safra
                      ? opportunity.contract_infos?.safra
                      : '-------'}
                  </Text>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Qtde. de Parcelas
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.contract_infos?.installment_quantity
                      ? opportunity.contract_infos?.installment_quantity
                      : '-------'}
                  </Text>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Parcelas em atraso
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.contract_infos?.installment_quantity_delayed
                      ? opportunity.contract_infos?.installment_quantity_delayed
                      : '-------'}
                  </Text>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Primeira parc. vencida
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.contract_infos?.installment_first_delayed
                      ? opportunity.contract_infos?.installment_first_delayed
                      : '-------'}
                  </Text>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Rastreador
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.contract_infos?.tracker ? 'Sim' : 'Não'}
                  </Text>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    CNPJ
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.store?.store_cnpj_number
                      ? formatCNPJ(String(opportunity.store?.store_cnpj_number))
                      : '-------'}
                  </Text>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Nome Fantasia
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.store?.name_fantasy ? opportunity.store?.name_fantasy : '-------'}
                  </Text>
                </VStack>
              </HStack>
            </VStack>

            <VStack space={4} bgColor="gray.30" borderRadius="10px" p={6}>
              <Text fontSize="16px" fontWeight={700} lineHeight="24px" color="gray.700">
                Dados do Cliente
              </Text>

              {/* @ts-ignore */}
              <HStack flexWrap="wrap" w="full" style={{ gap: '24px' }}>
                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Nome
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.customer_infos?.customer_name
                      ? opportunity.customer_infos?.customer_name
                      : '-------'}
                  </Text>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    CPF
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.customer_infos?.customer_cpf_cnpj
                      ? opportunity.customer_infos?.customer_cpf_cnpj
                      : '-------'}
                  </Text>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Telefone
                  </Text>
                  <HStack space={2}>
                    {opportunity.customer_infos?.phone_number_01 ||
                    opportunity.customer_infos?.phone_number_02 ? (
                      <>
                        {opportunity.customer_infos?.phone_number_01 && (
                          <Text fontSize="16px" fontWeight={400} color="gray.700">
                            {opportunity.customer_infos.phone_number_01}
                          </Text>
                        )}
                        {opportunity.customer_infos?.phone_number_02 && (
                          <Text fontSize="16px" fontWeight={400} color="gray.700">
                            {opportunity.customer_infos.phone_number_02}
                          </Text>
                        )}
                      </>
                    ) : (
                      <Text fontSize="16px" fontWeight={400} color="gray.700">
                        -------
                      </Text>
                    )}
                  </HStack>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Endereço
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.customer_infos?.address_city_neighborhood
                      ? opportunity.customer_infos?.address_city_neighborhood
                      : '-------'}
                  </Text>
                </VStack>
              </HStack>
            </VStack>

            <VStack space={4} bgColor="gray.30" borderRadius="10px" p={6}>
              <Text fontSize="16px" fontWeight={700} lineHeight="24px" color="gray.700">
                Dados do Veículo
              </Text>

              {/* @ts-ignore */}
              <HStack flexWrap="wrap" w="full" style={{ gap: '24px' }}>
                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Modelo
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.car_infos?.car_model
                      ? opportunity.car_infos?.car_model
                      : '-------'}
                  </Text>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Chassi
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.car_infos?.car_chassis_number
                      ? opportunity.car_infos?.car_chassis_number
                      : '-------'}
                  </Text>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Ano
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.car_infos?.car_year_model
                      ? opportunity.car_infos?.car_year_model
                      : '-------'}
                  </Text>
                </VStack>

                <VStack>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    Placa
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="gray.700">
                    {opportunity.car_infos?.car_license_plate
                      ? opportunity.car_infos?.car_license_plate
                      : '-------'}
                  </Text>
                </VStack>
              </HStack>
            </VStack>

            {handleGoToDetails && (
              <HStack justifyContent="flex-end">
                <Button
                  variant="outline"
                  colorScheme="gray"
                  px={6}
                  py="18px"
                  _text={{ fontSize: '14px', fontWeight: 700, lineHeight: '20px' }}
                  onPress={() =>
                    handleGoToDetails?.(
                      isCharge || isInsurance
                        ? opportunity?.opportunity_odc_id ?? ''
                        : opportunity?.id ?? '',
                      opportunity.opportunity_type_enum
                    )
                  }>
                  Ir para oportunidade
                </Button>
              </HStack>
            )}
          </VStack>
        )}
      </VStack>
    </TouchableOpacity>
  )
}
