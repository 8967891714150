import { useCallback, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  TStoresListItem,
  useConcludeWalletsManagementMutation,
  useGetStoresListInfinityQuery
} from 'integration/resources/walletsManagement'
import { useBreakpointValue, useDisclose, useToast } from 'native-base'
import { Toast } from 'organisms'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { TConcludeAnswersForm, UseConcludeManagementScreen } from './ConcludeManagementScreen.types'

const mergeStoresInfiniteQuery = (data: ReturnType<typeof useGetStoresListInfinityQuery>['data']) =>
  data?.pages.reduce<TStoresListItem[]>(
    //@ts-ignore
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const concludeAnswersFormSchema = yup.object().shape({
  stores: yup.array().of(
    yup.object().shape({
      description: yup
        .string()
        .max(300, 'A resposta pode ter no máximo 300 caracteres.')
        .min(3, 'A resposta precisa ter no mínimo 3 caracteres.')
    })
  )
})

export const useConcludeManagementScreen: UseConcludeManagementScreen = ({ navigation, route }) => {
  const { userStdCode } = route.params

  const { data: storesListData, isLoading: storesListLoading } = useGetStoresListInfinityQuery({
    leader_std_code: Number(userStdCode),
    assigned_stores: true
  })

  const storesData = useMemo(() => mergeStoresInfiniteQuery(storesListData), [storesListData])

  const unassignedStores = storesData.filter((item) => !item.assigned_to_user_name)

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  const {
    isOpen: goBackModalIsOpen,
    onClose: goBackModalOnClose,
    onOpen: goBackModalOnOpen
  } = useDisclose()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty }
  } = useForm<TConcludeAnswersForm>({
    mode: 'onChange',
    resolver: yupResolver(concludeAnswersFormSchema),
    defaultValues: {
      stores: unassignedStores.map((store) => ({
        ...store,
        description: ''
      }))
    }
  })

  const { mutate: concludeMutate, isLoading: concludeIsLoading } =
    useConcludeWalletsManagementMutation()

  const [timeouts, setTimeouts] = useState<number[]>([])

  const toast = useToast()

  const onSubmit = handleSubmit(
    useCallback<SubmitHandler<TConcludeAnswersForm>>(
      (data) => {
        const formattedData = {
          leader_std_code: Number(userStdCode),
          stores: data.stores.map((store) => ({
            store_tab_number: Number(store.store_tab_number),
            description: store.description
          }))
        }

        const clearAllTimeouts = () => {
          timeouts.forEach((timeoutId) => clearTimeout(timeoutId))

          setTimeouts([])
        }

        concludeMutate(formattedData, {
          onError() {
            const timeoutId = window.setTimeout(() => {
              handleGoBack()

              clearAllTimeouts()
            }, 1000)

            setTimeouts((prev) => [...prev, timeoutId])

            toast.show({
              render: () => <Toast type="error" text="Não foi possível completar a ação " />,
              placement: 'bottom',
              duration: 1500
            })
          },
          onSuccess() {
            const timeoutId = window.setTimeout(() => {
              handleGoBack()

              clearAllTimeouts()
            }, 1000)

            setTimeouts((prev) => [...prev, timeoutId])

            toast.show({
              render: () => <Toast type="success" text="Carteirização concluída 🎉" />,
              placement: 'bottom',
              duration: 1500
            })
          }
        })
      },
      [userStdCode, concludeMutate, handleGoBack, timeouts, toast]
    )
  )

  return {
    isMobile,
    handleGoBack,
    isLoading: storesListLoading,
    concludeIsLoading,
    unassignedStores,
    control,
    handleSubmit: onSubmit,
    errors,
    isValid,
    isDirty,
    goBackModalIsOpen,
    goBackModalOnClose,
    goBackModalOnOpen
  }
}
